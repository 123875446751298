@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:opsz@8..60&display=swap');
body {
  margin: 0;
 font-family: 'Quattrocento Sans'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 :root, [data-amplify-theme]{
  --amplify-components-button-link-hover-color:#08295C!important;
  --amplify-colors-primary-10: transparent!important;
  --amplify-colors-primary-20: #0B3A81!important;
  --amplify-colors-primary-20: #0B3A81!important;
  --amplify-colors-primary-40: #0B3A81!important;
  --amplify-colors-primary-60: #0B3A81!important;
  --amplify-colors-primary-80: #0B3A81!important;
  --amplify-colors-primary-90: #0B3A81!important;
  --amplify-colors-primary-100: #0B3A81!important;
 
}  
